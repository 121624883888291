
import { Grid, Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useNavigate, useParams, useLocation} from "react-router-dom";
import { useState } from 'react';

const EditAlt = () => {
    const location = useLocation();

    const currentAcc = location.state.account;

    const navigate = useNavigate();

    const { id } = useParams();
    
    const [altForm, setAltForm] = useState({
        username: "",
        password: "",
        riot_id: "",
        rank: "",
        tagline: "",
        notes: " "
    });


    const handleInputHooks = (e) => {
        setAltForm({ ...altForm, [e.target.name]: e.target.value });
    };


    const handleSubmit = (e) => {

        // Prevent reload
        e.preventDefault();
        
        let formData = new FormData();  //formdata object
       
        
        formData.append('username', altForm.username);   //append the values with key, value pair
        formData.append('password', altForm.password);
        formData.append('rank', altForm.rank);
        formData.append('notes', altForm.notes);

        if (altForm.username === '') formData.append('username', currentAcc.username);
        if (altForm.password === '') formData.append('password', currentAcc.password);
        if (altForm.notes === '') formData.append('notes', currentAcc.notes);
        if (altForm.rank === '') formData.append('rank', currentAcc.rank);
        if (altForm.riot_id === '') formData.append('riot_id', currentAcc.riot_id);
        if (altForm.tagline === '') formData.append('tagline', currentAcc.tagline);

        if (altForm.riot_id !== '') formData.append('riot_id', altForm.riot_id);
        if (altForm.tagline !== '') formData.append('tagline', altForm.tagline);

        // Make API calls here. 
        const url = "http://3.22.233.205:8000";;
        fetch(`${url}/accounts/val/${id}/update/`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
                'Accept': 'application/json'
            },
            referrerPolicy: 'no-referrer',
            body: formData
        })
        .catch((error) => console.error('Error:', error));

        navigate('/');
    }

    return(
        <>
            <Box 
                display='flex'
                justifyContent='center'
                sx={{ position: 'absolute', width: '100vw' }}
            >
                <Box 
                    display='flex'
                    justifyContent='center'
                    sx={{ width: '97%', backgroundColor: '#f6ebf4', borderRadius: '15px', marginTop: '2rem'}} 
                    component='form'
                    onSubmit={handleSubmit}
                >
                    <Grid 
                        container
                        spacing={2}
                        style={{"margin":"10px"}}
                    >
                        <Grid item sm={12}>
                            <h1 style={{"display": "flex", fontFamily: "Poppins", "justifyContent": "center", 'fontWeight': 'bold', 'color': '#2a2a2a'}} >
                                Edit Account
                            </h1>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextField
                                name='username'
                                defaultValue={currentAcc.username}
                                label='username'
                                size='small'
                                required
                                variant='standard'
                                fullWidth
                                onChange={handleInputHooks}
                                style={{fontFamily: "Poppins"}}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextField
                                defaultValue={currentAcc.password}
                                name='password'
                                label='password'
                                size='small'
                                required
                                variant='standard'
                                fullWidth
                                onChange={handleInputHooks}
                                style={{fontFamily: "Poppins"}}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            {/* <TextField
                                name='rank'
                                label='rank'
                                size='small'
                                required
                                variant='standard'
                                fullWidth
                                onChange={handleInputHooks}
                            /> */}
                            <h6 style={{ "paddingTop": "1rem"}} >
                                Select Rank
                            </h6>
                            <select name='rank' label='rank' required onChange={handleInputHooks} class="form-select" size="1" style={{backgroundColor: "#f6ebf4"}} defaultValue={currentAcc.rank}>
                                <option value="Unranked">Unranked</option>
                                <option value="Iron I">Iron I</option>
                                <option value="Iron II">Iron II</option>
                                <option value="Iron III">Iron III</option>
                                <option value="Bronze I">Bronze I</option>
                                <option value="Bronze II">Bronze II</option>
                                <option value="Bronze III">Bronze III</option>
                                <option value="Silver I">Silver I</option>
                                <option value="Silver II">Silver II</option>
                                <option value="Silver III">Silver III</option>
                                <option value="Gold I">Gold I</option>
                                <option value="Gold II">Gold II</option>
                                <option value="Gold III">Gold III</option>
                                <option value="Platinum I">Platinum I</option>
                                <option value="Platinum II">Platinum II</option>
                                <option value="Platinum III">Platinum III</option>
                                <option value="Diamond I">Diamond I</option>
                                <option value="Diamond II">Diamond II</option>
                                <option value="Diamond III">Diamond III</option>
                                <option value="Ascendant I">Ascendant I</option>
                                <option value="Ascendant II">Ascendant II</option>
                                <option value="Ascendant III">Ascendant III</option>
                                <option value="Immortal I">Immortal I</option>
                                <option value="Immortal II">Immortal II</option>
                                <option value="Immortal III">Immortal III</option>
                                <option value="Radiant">Radiant</option>
                            </select>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <TextField
                                name='riot_id'
                                label='riot_id'
                                size='small'
                                defaultValue={currentAcc.riot_id}
                                variant='standard'
                                fullWidth
                                onChange={handleInputHooks}
                                style={{fontFamily: "Poppins"}}
                            />
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <TextField
                                name='tagline'
                                label='tagline'
                                size='small'
                                defaultValue={currentAcc.tagline}
                                style={{fontFamily: "Poppins"}}
                                variant='standard'
                                fullWidth
                                onChange={handleInputHooks}
                            />
                            <TextField
                                name='notes'
                                label='notes'
                                size='small'
                                variant='standard'
                                defaultValue={currentAcc.notes}
                                fullWidth
                                onChange={handleInputHooks}
                                style={{fontFamily: "Poppins"}}
                            />
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <Button
                                type='submit'
                                variant='contained'
                                fullWidth
                                style={{'backgroundColor': '#2a2a2a', fontFamily: "Poppins"}}
                            >
                            SAVE
                            </Button>
                        </Grid>

                    </Grid> 
                </Box>

            </Box>
        </>
    )
}
export default EditAlt;