import { Button, Card, Box, Typography, CardContent, ListItem, Divider, ListItemText, List } from '@mui/material';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const navigate = useNavigate();

    function formatData(data) {
        var iron = [];
        var bronze = [];
        var silver = [];
        var gold = [];
        var plat = [];
        var dia = [];
        var asc = [];
        var imm = [];
        var rad = [];

        data.forEach(acc => {
            if (acc.rank.includes("Iron")) {
                iron.push(acc)
            }
            if (acc.rank.includes("Bronze")) {
                bronze.push(acc)
            }
            if (acc.rank.includes("Silver")) {
                silver.push(acc)
            }
            if (acc.rank.includes("Gold")) {
                gold.push(acc)
            }
            if (acc.rank.includes("Platinum")) {
                plat.push(acc)
            }
            if (acc.rank.includes("Diamond")) {
                dia.push(acc)
            }
            if (acc.rank.includes("Ascendant")) {
                asc.push(acc)
            }
            if (acc.rank.includes("Immortal")) {
                imm.push(acc)
            }
            if (acc.rank.includes("Radiant")) {
                rad.push(acc)
            }
        })

        return {
            "Iron accounts": iron.length,
            "Bronze accounts": bronze.length,
            "Silver accounts": silver.length,
            "Gold accounts": gold.length,
            "Platinum accounts": plat.length,
            "Diamond accounts": dia.length,
            "Ascendant accounts": asc.length,
            "Immortal accounts": imm.length,
            "Radiant accounts": rad.length
        }
    }

    const [userInfo, setUserInfo] = useState({
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        avatar: "",
        accounts: []
    });

    const [accounts, setAccounts] = useState([]);

    const handleDeleteAccount = () => {
        // call the delete account endpoint
        const url = "http://3.22.233.205:8000";;
        fetch(`${url}/accounts/delete/`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
            .then(data => data.json())
            .catch((error) => console.error('Error:', error));
        // log the user out
        localStorage.removeItem('token');
        // send user to the signin page
        navigate('/signin');
    }

    const loadAccounts = () => {
        const url = "http://3.22.233.205:8000";;
        fetch(`${url}/accounts/val/all/`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            })
            .then(data => data.json())
            .then(res => {
                setAccounts(res)
            })
            .catch(error => console.log(error));
    };

    useEffect(() => {
        loadAccounts();
    }, [])

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            console.log("not logged in")
            navigate('/signin')
            return
        }

        const url = "http://3.22.233.205:8000";;
        fetch(`${url}/accounts/profile/`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            referrerPolicy: 'no-referrer',
        })
            .then(data => data.json())
            .then(res => {
                setUserInfo({
                    username: res['user'].username,
                    first_name: res['user'].first_name,
                    last_name: res['user'].last_name,
                    email: res['user'].email,
                    avatar: res['user'].avatar,
                    accounts: accounts
                })
            })
            .catch((error) => console.error('Error:', error));
    }, []);


    if (!localStorage.getItem('token')) {
        navigate('/signin')
        return
    }

    const addUrl = (str) => {
        const url = "http://3.22.233.205:8000";;
        return url + str
    }

    const formattedAccountData = formatData(accounts);
    console.log(formattedAccountData)

    return (
        <>
            <Box>
                <Typography style={{ 'fontStyle': 'bold', 'margin': '2rem', 'fontWeight': 'bold', 'color': '#F7B538', fontFamily: "Poppins" }} variant="h3">My Profile</Typography>
                <hr style={{ 'border': '1px solid #F7B538' }}></hr>

                <Card sx={{
                    margin: '2em', 'padding': '1em', 'border-radius': '15px', 'background': '#f6ebf4', 'boxShadow': '0'
                }}>
                    <CardContent>
                        <Typography style={{ 'fontWeight': 'bold', fontFamily: "Poppins" }} gutterBottom variant="h4" component="div">
                            {userInfo.first_name} {userInfo.last_name}
                            <hr></hr>
                        </Typography>
                        <Typography style={{ 'fontWeight': 'bold', 'color': '#2a2a2a', fontFamily: "Poppins" }} variant="h5" color="text.secondary">
                            {userInfo.username}
                        </Typography>

                        <Box sx={{ marginTop: "1em" }}>
                            <img src={addUrl(userInfo.avatar)} alt="avatar" style={{ 'width': 160, 'height': 160 }}></img>
                        </Box>
                        <Typography variant="caption" color="text.secondary" style={{ fontFamily: "Poppins" }}>
                            {userInfo.email}
                        </Typography>
                        <br></br><br></br>
                        <Typography style={{ fontFamily: "Poppins" }}>
                            Accounts
                        </Typography>
                        <List style={{ 'columnCount': 2, 'fontSize': '12px', 'fontStyle': 'bold', 'fontWeight': 'bold', 'color': '#151515', fontFamily: "Poppins" }}>
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: { 'fontSize': "12px", 'fontFamily': "Poppins" } }} primary="Iron" />{formattedAccountData['Iron accounts']}
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: { 'fontSize': "12px", 'fontFamily': "Poppins" } }} primary="Bronze" />{formattedAccountData['Bronze accounts']}
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: { 'fontSize': "12px", 'fontFamily': "Poppins" } }} primary="Silver" />{formattedAccountData['Silver accounts']}
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: { 'fontSize': "12px", 'fontFamily': "Poppins" } }} primary="Gold" />{formattedAccountData['Gold accounts']}
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: { 'fontSize': "12px", 'fontFamily': "Poppins" } }} primary="Platinum" />{formattedAccountData['Platinum accounts']}
                            </ListItem>
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: { 'fontSize': "12px", 'fontFamily': "Poppins" } }} primary="Diamond" />{formattedAccountData['Diamond accounts']}
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: { 'fontSize': "12px", 'fontFamily': "Poppins" } }} primary="Ascendant" />{formattedAccountData['Ascendant accounts']}
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: { 'fontSize': "12px", 'fontFamily': "Poppins" } }} primary="Immortal" />{formattedAccountData['Immortal accounts']}
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: { 'fontSize': "12px", 'fontFamily': "Poppins" } }} primary="Radiant" />{formattedAccountData['Radiant accounts']}
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: { 'fontSize': "16px", 'fontFamily': "Poppins", "fontWeight": 'bold' } }} primary="TOTAL" />{accounts.length}
                            </ListItem>
                        </List>
                    </CardContent>

                    <Button
                        style={{ 'marginLeft': '1em', 'backgroundColor': '#2a2a2a', fontFamily: "Poppins" }}
                        color="info"
                        variant='contained'
                        onClick={() => navigate('/profile/edit', { state: { account: userInfo } })}>Edit Info</Button>
                    <Button
                        style={{ 'marginLeft': '1em', 'backgroundColor': '#2a2a2a', fontFamily: "Poppins" }}
                        color="error"
                        variant='contained'
                        onClick={() => handleDeleteAccount()}>Delete User</Button>
                </Card>
            </Box>
        </>
    )
}


export default Profile