import { Button } from "@mui/material";
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from "react-router-dom";
import { Card, CardContent, Typography, Grid } from '@mui/material';

const SearchAcc = () => {

    const location = useLocation();

    const rank = location.state.rank;

    const [accounts, setAccounts] = useState([]);

    const navigate = useNavigate();

    const deleteAccount = (id) => {
        const url = "http://3.22.233.205:8000";;
        fetch(`${url}/accounts/val/${id}/delete/`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            referrerPolicy: 'no-referrer',
        })
            .then(data => data.json())
            .catch((error) => console.error('Error:', error));
        window.location.reload()
    }

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/signin')
            return
        }

        // fetch(url.toString(), {
        const url = "http://3.22.233.205:8000";;
        fetch(`${url}/accounts/val/search/${rank}/`, {
            method: 'GET',
            referrerPolicy: 'no-referrer',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Accept': 'application/json'
            },
        })
            .then(data => data.json())
            .then(res => {
                setAccounts(res);
            })
            .catch((error) => console.error('Error:', error));
    }, [rank]);


    const allAccounts = [];
    if (accounts.length !== 0) {
        accounts.forEach(function (item) {
            allAccounts.push(
                <>
                    <Grid item xs={12} sm={'auto'} md={'auto'}>
                        <Card sx={{
                            margin: '2.1rem', 'border-radius': '15px', 'background': '#f6ebf4', minHeight: '380px', minWidth: '400px', boxShadow: '12px 12px 24px #191919, -12px -12px 24px #1f1f1f'
                        }}>
                            <CardContent>
                                <Typography style={{ 'fontWeight': 'bold', fontFamily: "Poppins" }} gutterBottom variant="h4" component="div">
                                    {item.username}
                                </Typography>
                                <Typography style={{ 'fontWeight': 'bold', fontFamily: "Poppins" }} gutterBottom variant="h5" component="div">
                                    {item.rank}
                                    <hr></hr>
                                </Typography>
                                {item.riot_id && item.tagline &&
                                    <Typography style={{ 'fontWeight': 'bold', 'color': '#F7B538', fontFamily: "Poppins" }} variant="h5" color="text.secondary">
                                        {item.riot_id}#{item.tagline}
                                    </Typography>
                                }

                                <Box sx={{ marginTop: "1em" }}>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        <h4 style={{ 'display': 'inline', 'fontWeight': 'bold', fontFamily: "Poppins" }}>Username:</h4> <h6 style={{ 'display': 'inline', fontFamily: "Poppins" }}> {item.username}</h6>
                                        <br></br>
                                        <h4 style={{ 'display': 'inline', 'fontWeight': 'bold', fontFamily: "Poppins" }}>Password:</h4> <h6 style={{ 'display': 'inline', fontFamily: "Poppins" }}> {item.password}</h6>
                                    </Typography>
                                    <Typography style={{ 'fontWeight': 'bold', fontFamily: "Poppins" }} variant="subtitle2" color="text.secondary">
                                        Added: {item.created_at}
                                    </Typography>
                                    <Typography style={{ 'fontWeight': 'bold', fontFamily: "Poppins" }} variant="subtitle2" color="text.secondary">
                                        Updated: {item.updated_at}
                                    </Typography>
                                    <Typography style={{ 'fontWeight': 'bold', fontFamily: "Poppins", color: "#F7B538" }} variant="subtitle2" color="text.secondary">
                                        Notes: {item.notes}
                                    </Typography>
                                </Box>

                            </CardContent>
                            <Button
                                color="info"
                                variant='contained'
                                style={{ 'backgroundColor': '#2a2a2a', color: "#F7B538", fontFamily: "Poppins", marginLeft: '1rem' }}
                                onClick={() => navigator.clipboard.writeText(`${item.username}-${item.password}`).then(() => alert("Login details copied to clipboard"))
                                    .catch((e) => alert(e.message))}>📋</Button>
                            <Button
                                color="info"
                                variant='contained'
                                style={{ 'backgroundColor': '#2a2a2a', color: "#F7B538", marginLeft: '.5rem', fontFamily: "Poppins" }}
                                onClick={() => navigate(`/${item.id}/update`, { state: { account: item } })}>📝</Button>
                            {/* onClick={EditAlt}>Edit</Button> */}
                            <Button
                                color="error"
                                variant='contained'
                                style={{ 'backgroundColor': '#2a2a2a', color: "#F7B538", marginLeft: '.5rem', fontFamily: "Poppins" }}
                                onClick={() => deleteAccount(item.id)}>🗑️</Button>

                        </Card>

                    </Grid>
                </>
            )
        })
    }
    if (accounts.length === 0) {
        return (
            <>
                <Typography style={{ 'fontStyle': 'bold', 'margin': '2rem', 'fontWeight': 'bold', 'color': '#F7B538', fontFamily: "Poppins" }} variant="h2">No Accounts Found Ranked - {rank.toUpperCase()}</Typography>
            </>
        )
    }

    return (
        <>
            <Typography style={{ 'fontStyle': 'bold', 'margin': '2rem', 'fontWeight': 'bold', 'color': '#F7B538', fontFamily: "Poppins" }} variant="h3">Accounts Ranked - {rank.toUpperCase()}</Typography>
            <hr style={{ 'border': '1px solid #4257AD' }}></hr>
            <Grid container sx={{ marginLeft: "10px" }}> {allAccounts} </Grid>
        </>
    )

}
export default SearchAcc;