import React, { useState } from 'react';

import { TextField, Grid, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const SignIn = () => {
    const navigate = useNavigate();
    const [signinForm, setSigninForm] = useState({
        username: '',
        password: '',
    });


    // handle subsequent hook for the input field along with it's error
    const handleInputHooks = (e) => {
        setSigninForm({ ...signinForm, [e.target.name]: e.target.value });
    };

    // On submit function!
    const handleSubmit = (e) => {
        // Prevent reload
        e.preventDefault();
        // Make API calls here. 

        const url = "http://3.22.233.205:8000";;
        fetch(`${url}/accounts/signin/`, {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(signinForm)
        })
        .then(data => data.json())
        .then(res => {
            if (res.access){ 
                localStorage.setItem("token", res.access);
            } else {
                localStorage.removeItem('token')
            }
            navigate('/profile');
        })
        .catch((error) => console.error('Error:', error));
    
        // 2. send user to the signin page
    }

    if (localStorage.getItem('token')){
        return (
        <>
            <Typography style={{'fontStyle': 'bold', 'margin': '2rem', 'fontWeight': 'bold', 'color': '#F7B538', fontFamily: "Poppins" }} variant="h3">You are already logged in!</Typography>
            <Button
                    type='submit'
                    variant='contained'
                    style={{'marginLeft': '1.5em',  'backgroundColor': '#2a2a2a'}}
                    onClick={() => navigate('/')}
            >
            Take Me Home
            </Button>
        </>
        )
    }

    return (
    <Box
        display='flex'
        justifyContent='center'
        sx={{ position: 'absolute', width: '100vw' }}
    >
        <Box 
            display='flex'
            justifyContent='center'
            sx={{  width: '30%', borderRadius: '15px', marginTop: '2rem'}} 
            component='form'
        >
            <Grid 
                container
                spacing={2}
                style={{"margin": "10px"}}
            >
                <Grid item sm={12}>
                <Typography style={{'fontStyle': 'bold', 'margin': '2rem', 'fontWeight': 'bold', 'color': '#f6ebf4', fontFamily: "Poppins" }} variant="h2">Valorant Accs</Typography>
                <Typography style={{'fontStyle': 'bold', 'margin': '2rem', 'fontWeight': 'bold', 'color': '#F7B538', fontFamily: "Poppins" }} variant="h3">Sign In</Typography>
                <hr style={{'border': '1px solid #F7B538'}}></hr>
                </Grid>
                <Grid item sm={12}>
                    <TextField
                        name='username'
                        label='username'
                        size='small'
                        variant='standard'
                        fullWidth
                        sx = {{
                            input: {
                                color: "white"
                            }
                        }}
                        required
                        onChange={handleInputHooks}
                    />
                </Grid>
                <Grid item sm={12}>
                    <TextField
                        name='password'
                        label='password'
                        type={'password'}
                        size='small'
                        sx = {{
                            input: {
                                color: "white"
                            }
                        }}
                        variant='standard'
                        fullWidth
                        required
                        onChange={handleInputHooks}
                    />
                </Grid>
                <Grid item sm={6}>
                    <Button
                        type='submit'
                        variant='contained'
                        fullWidth
                        style={{'marginTop': '1.5em', 'backgroundColor': '#2a2a2a'}}
                        onClick={handleSubmit}
                    >
                    Sign In
                    </Button>
                </Grid>

                <Grid item sm={6}>
                    <Button
                        type='submit'
                        variant='contained'
                        fullWidth
                        style={{'marginTop': '1.5em', 'backgroundColor': '#2a2a2a'}}
                        onClick={() => navigate('/signup')}
                    >
                    Sign Up
                    </Button>
                </Grid>

            </Grid> 
        </Box>

    </Box>
    )
}

export default SignIn