import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const SignOut = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('token')
        navigate('/signin')
        return
        },
        // fetch(url.toString(), {
    []);

    return (
    <>
        <h2>Signed Out</h2>
    </>
    )
}

export default SignOut

