import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Outlet, useNavigate } from "react-router-dom";
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import SearchAcc from '../../pages/LandingPage/search';

const NavBar = () => {

  const url = "http://3.22.233.205:8000";;
  const addUrl = (str) => {
    return url + str
  }

  const [userInfo, setUserInfo] = React.useState({})

  React.useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/signin')
      return
    }
    fetch(`${url}/accounts/profile/`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      referrerPolicy: 'no-referrer',
    })
      .then(data => data.json())
      .then(res => {
        if (res['user'] !== undefined) {
          setUserInfo(res['user'])
        }
      })
      .catch((error) => console.error('Error:', error));
  }, [])


  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '10%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      paddingLeft: '1rem',
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

  return (
    <>
      <AppBar style={{ 'backgroundColor': '#151515', 'color': '#F7B538' }} position="static">
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Tooltip title="Home Page">
              <MenuItem onClick={() => navigate("/")}>
                <img src="logo512.png" style={{ 
                  "max-height": "50px", 
                  "max-width": "50px" 
                  }} />
              </MenuItem>
            </Tooltip>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title="Add An Account">
                <Button
                  onClick={() => navigate("/add")}
                  sx={{
                    my: 2,
                    color: '#F7B538',
                    display: 'block',
                    fontWeight: 600,
                    padding: "8px 10px",
                    letterSpacing: 2,
                    marginLeft: "22px",
                    fontFamily: "Poppins",
                    // boxShadow: "-2px -2px 8px rgb(34, 34, 34), -2px -2px 12px rgba(54, 54, 54, .3), inset 2px 2px 4px rgba(36, 36, 36, 0.1), 2px 2px 12px rgba(0,0,0,.7)",
                    "&:hover": {
                      boxShadow: "inset -2px -2px 8px rgb(34, 34, 34), inset -2px -2px 12px rgba(54, 54, 54, 0.3), 2px 2px 4px rgba(36, 36, 36, 0.1), inset 2px 2px 12px rgba(0,0,0,.7); transition: 100ms ease-in-out"
                    },
                  }}
                >          Add Alt          </Button>
              </Tooltip>
              <Tooltip title="View All Accounts">
                <Button
                  onClick={() => navigate("/")}
                  sx={{
                    my: 2,
                    color: '#F7B538',
                    display: 'block',
                    fontWeight: 600,
                    letterSpacing: 2,
                    padding: "8px 10px",
                    marginLeft: "22px",
                    fontFamily: "Poppins",
                    // boxShadow: "-2px -2px 8px rgb(34, 34, 34), -2px -2px 12px rgba(54, 54, 54, .3), inset 2px 2px 4px rgba(36, 36, 36, 0.1), 2px 2px 12px rgba(0,0,0,.7)",
                    "&:hover": {
                      boxShadow: "inset -2px -2px 8px rgb(34, 34, 34), inset -2px -2px 12px rgba(54, 54, 54, 0.3), 2px 2px 4px rgba(36, 36, 36, 0.1), inset 2px 2px 12px rgba(0,0,0,.7); transition: 100ms ease-in-out"
                    },
                  }}
                >          All Alts          </Button>
              </Tooltip>
            </Box>
            <Search
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  navigate('/search', { state: { rank: event.target.value } });
                  // navigate(<SearchAcc rank={event.target.value}/>)
                }
              }}
            >

              <StyledInputBase
                style={{ fontWeight: 300, borderRadius: "12px", fontFamily: "Poppins" }}
                placeholder="Search by Rank 🔎"
              />
            </Search>

            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}
              onClick={() => navigate("/profile")}>
              <MenuItem>
                {userInfo.username !== undefined &&
                  <Typography style={{ padding: '10px', fontWeight: 600, fontFamily: "Poppins", letterSpacing: 1 }}>{userInfo.username}</Typography>}
              </MenuItem>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Profile Options">
                <IconButton onClick={handleOpenUserMenu} sx={{
                  p: 0,
                  "&:hover": {
                    boxShadow: " -2px -2px 5px rgb(247, 181, 56), 0px 0px 100px rgba(247, 181, 56, .5), inset 0px 0px 100px rgba(247, 181, 56, .13), 2px 2px 5px rgba(247, 181, 56, 1)"
                  },
                  transition: "500ms ease-in-out"
                }}>
                  {userInfo.avatar && <Avatar src={addUrl(userInfo.avatar)} />}
                  {!userInfo.avatar && <Avatar src="https://img.icons8.com/ios-glyphs/90/ffffff/user-male-circle.png" />}
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >

                <MenuItem onClick={() => {
                  handleCloseUserMenu();
                  navigate("/profile")
                }}>
                  <Typography sx={{ fontFamily: "Poppins" }} textAlign="center">Profile Page</Typography>
                </MenuItem>
                {!localStorage.getItem('token') && <MenuItem onClick={() => {
                  handleCloseUserMenu();
                  navigate("/signup")
                }}>
                  <Typography sx={{ fontFamily: "Poppins" }} textAlign="center">Sign Up</Typography>
                </MenuItem>}
                {!localStorage.getItem('token') && <MenuItem onClick={() => {
                  navigate("/signin")
                }}>
                  <Typography sx={{ fontFamily: "Poppins" }} textAlign="center">Sign in</Typography>
                </MenuItem>}
                {localStorage.getItem('token') && <MenuItem onClick={() => {
                  localStorage.removeItem('token')
                  navigate("/signin")
                  window.location.reload();
                }}>
                  <Typography sx={{ fontFamily: "Poppins" }} textAlign="center">Sign out</Typography>
                </MenuItem>}
              </Menu>
            </Box>

          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </>
  );
};
export default NavBar;
