import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Profile from "./pages/Profile"
import SignUp from "./pages/SignUp"
import SignIn from "./pages/SignIn"
import ProfileEdit from './pages/Profile/ProfileEdit';
import SignOut from './pages/SignOut';
import LandingPage from './pages/LandingPage';
import NavBar from './components/Layout/nav_bar';
import AddAlt from './pages/LandingPage/addalt';
import EditAlt from './pages/LandingPage/editalt';
import SearchAcc from './pages/LandingPage/search';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
        <Route path="signin" element={<SignIn/>}/>
        <Route path="signout" element={<SignOut/>}/>
        <Route path="signup" element={<SignUp/>}/>
          <Route path="/" element={<NavBar/>}>
            <Route index element={<LandingPage/>}/>
            <Route path="/bar" element={<NavBar/>}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="profile/edit" element={<ProfileEdit/>}/>
            <Route path="/search" element={<SearchAcc/>}/>
            <Route path="add" element={<AddAlt/>}/>
            <Route path=":id/update" element={<EditAlt/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
